import AOS from 'aos';
import 'aos/dist/aos.css';

window.smoothScroll = function(id) {
    document.querySelector(id).scrollIntoView({
        behavior: 'smooth'
      });
}

AOS.init();

window.onload = function (){
    if (window.innerWidth < 992) {
        $('nav #contact').addClass('btn-blue');
        $('nav #contact').attr('style', 'color: white !important');
    }

    if (window.pageYOffset != 0){
        $('nav').addClass('nav-scroll');
        $('#navbarSupportedContent').removeClass('collapse-shadow');
    }

    if (location.href.indexOf('#')){
        let url = location.href.substr(location.href.indexOf('#')+1)
        url = $('.flag-nav .dropdown-menu a').attr('href') + '/?redirect=' + url;
        $('.flag-nav .dropdown-menu a').attr('href', url);
    }

    if (location.href.indexOf('/en') != -1 && location.href.indexOf('#why-us') != -1){
        let numberOfReviews = $('.oferteo-widget-reviews .more-rewievs').text().match(/\d+/)[0];
        $('.oferteo-widget-reviews .more-rewievs').text(numberOfReviews + ' customer reviews');
        $('.oferteo-widget-reviews .box-text .text-info').html('Are you a satisfied customer? </br> Leave your references.');
        $('.oferteo-widget-reviews .top .box-btn .btn-orange').html('Add opinion');

        let rewievs = $('.oferteo-widget-reviews .rewievs .text-rewievs');
        for (let i = 0; i < rewievs.length; i++){
            if (rewievs.eq(i).text().indexOf('Najwyższy poziom obsługi z jakim się spotkałem. Szczerze polecam firmę Business Web Solutions, przez kilkanaście lat w branży ecommerce współpracowałem już z wieloma firmami programistycznymi, czy programistami freelancerami, ale nigdy nie byłem tak zadowolony. Bardzo dobry kontakt co jest podstawą zrozumienia czego oczekuję przy projekcie, szybkie i jasne odpowiedzi zawierające również podpowiedzi jak można inaczej rozwiązać problem. Słowność i terminowość, choć wiem, że projekty lubią się przeciągać to takich sytuacji nie miałem, zawsze podany termin był dotrzymywany. Na pochwałę zasługuje również bardzo profesjonalne podejście do projektów, rozpisywanie etapów, szczegółowość to co później przekłada się na termin, ale też koszt. Kolejne projekty na pewno będę realizował u Pana Jakuba.') != -1){
                rewievs.eq(i).text('The greatest level of service I have ever encountered. I sincerely recommend Business Web Solutions, for many years in the e-commerce industry I have worked with many programming companies, or freelance programmers, but I have never been so satisfied. Very good contact, which is the basis for understanding which I expect during a project, quick and clear answers, also containing hints how to solve a problem in a different way. They are prompt and punctual, despite knowing that projects like to drag on, I never experienced such situations and they always met the deadline. I would also like to praise the very professional approach to projects, the description of stages, the detail that later on translates into the deadline and also, the cost. I will certainly continue working with Jakub on future projects.');
            } else if(rewievs.eq(i).text().indexOf('Polecam współpracę z tą firmą.br/Współpracuje z nią kilka lat i jestem zadowolony z jakości dostarczanych rozwiązań i kontaktu.br/Polecam!') != -1){
                rewievs.eq(i).text('I recommend cooperation with this company. I have been cooperating with it for several years and I am satisfied with the quality of the provided services and contact. Strongly recommend!');
            } else if (rewievs.eq(i).text().indexOf('Szybko, fachowo, z dobrą energią i jeszcze niedrogo... Pakiet wręcz idealny. Odkąd zacząłem współpracę z Business Web Solutions mój system stanął na nogi i działa pod względem technicznym bardzo dobrze. Oceniam wiedzę i doświadczenie Pana Jakuba i jego Współpracowników o kilka poziomów wyższej niż przeciętni wykonawcy, których dużo ogłasza się w Internecie. Wiem z doświadczenia, przerobiłem kilkunastu przez te lata działalności internetowej. Szczerze i gorąco polecam usługi firmy BWS! Naprawdę, znają się na tym co robią, a nie tylko tak twierdzą.') != -1){
                rewievs.eq(i).text('Fast, competent, with good energy and still inexpensive... The perfect package. Since I started working with Business Web Solutions, my system is back on its feet and works very well from a technical point of view. I rate the knowledge and experience of Jakub and his associates many levels higher than the average contractors advertised on the Internet. I know this from experience, I have worked with several others over the years operating on the Internet. I sincerely and highly recommend the services of BWS! They really know what they are doing, and don\'t just claim to know.');
            } else if (rewievs.eq(i).text().indexOf('Polecam. Terminowe wykonanie, dobry kontakt, wiedzą i umiejętności.br/') != -1){
                rewievs.eq(i).text('I recommend. Punctual execution, good contact, knowledge and skills.');
            } else if (rewievs.eq(i).text().indexOf('Profesjonalne i dokładne wykonanie projektu zgodnie z założeniami. Świetny kontakt oraz organizacja ze strony firmy. Nieraz jeszcze skorzystam z ich usług. Polecam każdemu kto szuka tylko najlepszych i nowoczesnych rozwiązań.') != -1){
                rewievs.eq(i).text('Professional and accurate execution of the project as intended. Great contact and organisation on the part of the company. I will use their services on more than one occasion. I would recommend them to anyone who is looking for the best and modern solutions.');
            }

            let translatedDate = translateMonths($('.oferteo-widget .rewievs .details-rewievs').eq(i).text());
            $('.oferteo-widget .rewievs .details-rewievs').eq(i).text(translatedDate);
        }
    }
}

function translateMonths(date){
    let translatedMonth = date;

    if (date.indexOf('stycznia') != -1) {
        translatedMonth = date.replace('stycznia', 'January');
    } else if(date.indexOf('lutego') != -1){
        translatedMonth = date.replace('lutego', 'February');
    } else if(date.indexOf('marca') != -1){
        translatedMonth = date.replace('marca', 'March');
    } else if(date.indexOf('kwietnia') != -1){
        translatedMonth = date.replace('kwietnia', 'April');
    } else if(date.indexOf('maja') != -1){
        translatedMonth = date.replace('maja', 'May');
    } else if(date.indexOf('czerwca') != -1){
        translatedMonth = date.replace('czerwca', 'June');
    } else if(date.indexOf('lipca') != -1){
        translatedMonth = date.replace('lipca', 'July');
    } else if(date.indexOf('sierpnia') != -1){
        translatedMonth = date.replace('sierpnia', 'August');
    } else if(date.indexOf('września') != -1){
        translatedMonth = date.replace('września', 'September');
    } else if(date.indexOf('października') != -1){
        translatedMonth = date.replace('października', 'October');
    } else if(date.indexOf('listopada') != -1){
        translatedMonth = date.replace('listopada', 'November');
    } else if(date.indexOf('grudnia') != -1){
        translatedMonth = date.replace('grudnia', 'December');
    }

    return translatedMonth;
}

$(document).scroll(function (){
    if (window.pageYOffset == 0){
        if ($('nav').hasClass('nav-scroll')) {
            $('nav').removeClass('nav-scroll');
            $('#navbarSupportedContent').removeClass('show');
            $('.body-shadow').hide();
            $('#navbarSupportedContent').addClass('collapse-shadow');

            if (window.innerWidth >= 992){
                if ($('.hero_bg').length > 0){
                    $('nav #contact').removeClass('btn-blue');
                }
                $('nav #contact').attr('style', 'color: #212529');
            }
        }
        $('nav').removeClass('mobile-shadow');
    } else {
        if (!$('nav').hasClass('nav-scroll')){
            $('nav').addClass('nav-scroll');
            $('#navbarSupportedContent').removeClass('collapse-shadow');

            if (window.innerWidth >= 992){
                if ($('.hero_bg').length > 0) {
                    $('nav #contact').addClass('btn-blue');
                }
            $('nav #contact').attr('style', 'color: white !important');
            }
        }
    }
});
